import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'equipo/:id',
    loadChildren: () => import('./equipo/equipo.module').then( m => m.EquipoPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'crear-equipo',
    loadChildren: () => import('./modales/crear-equipo/crear-equipo.module').then( m => m.CrearEquipoPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'crear-jugador',
    loadChildren: () => import('./modales/crear-jugador/crear-jugador.module').then( m => m.CrearJugadorPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'tallaje',
    loadChildren: () => import('./tallaje/tallaje.module').then( m => m.TallajePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'crear-entrenador',
    loadChildren: () => import('./modales/crear-entrenador/crear-entrenador.module').then( m => m.CrearEntrenadorPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'coordinador/equipo/:id',
    loadChildren: () => import('./coordinador/equipo/equipo.module').then( m => m.EquipoPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'coordinador/entrenadores',
    loadChildren: () => import('./coordinador/entrenadores/entrenadores.module').then( m => m.EntrenadoresPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'coordinador/direccion',
    loadChildren: () => import('./coordinador/direccion/direccion.module').then( m => m.DireccionPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'coordinador-general/equipo/:id',
    loadChildren: () => import('./coordinador-general/equipo/equipo.module').then( m => m.EquipoPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'coordinador-general/usuarios',
    loadChildren: () => import('./coordinador-general/usuarios/usuarios.module').then( m => m.UsuariosPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'coordinador-general/jugadores',
    loadChildren: () => import('./coordinador-general/jugadores/jugadores.module').then( m => m.JugadoresPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'editar-entrenador',
    loadChildren: () => import('./modales/editar-entrenador/editar-entrenador.module').then( m => m.EditarEntrenadorPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'editar-coordinador',
    loadChildren: () => import('./modales/editar-coordinador/editar-coordinador.module').then( m => m.EditarCoordinadorPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'crear-club',
    loadChildren: () => import('./modales/crear-club/crear-club.module').then( m => m.CrearClubPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'crear-coordinador',
    loadChildren: () => import('./modales/crear-coordinador/crear-coordinador.module').then( m => m.CrearCoordinadorPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'ficheros/:id',
    loadChildren: () => import('./ficheros/ficheros.module').then( m => m.FicherosPageModule),
    canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
